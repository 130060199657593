import React from "react";
import constants from "../../utils/constants";

export default () => {
  return (
    <>
      <section id="map">
        <div>
          <iframe
            title={'Gmaps'}
            src={constants.MAP_IFRAME_LINK}
            width="100%"
            height="500"
            frameBorder="0"
            style={{ border: 0 }}
            allowFullScreen=""
            aria-hidden="false"
            // eslint-disable-next-line
            tabIndex="0"
          />
        </div>
      </section>
    </>
  );
};
